@font-face {
  font-family: 'Impact';
  src: local('Impact'), url('./assets/fonts/Impact.ttf') format('truetype');
}
@font-face {
  font-family: 'Comic Sans MS';
  src: local('Comic Sans MS'), url('./assets/fonts/ComicSansMS.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url('./assets/fonts/Ubuntu.ttf') format('truetype');
}
@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url('./assets/fonts/Сalibri.ttf') format('truetype');
}
@font-face {
  font-family: 'Arial';
  src: local('Arial'), url('./assets/fonts/Arial.ttf') format('truetype');
}

#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  height: 100%;
}
html {
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(123, 117, 117, 0.3);
  box-shadow: inset 0 0 6px rgba(123, 117, 117, 0.3);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(123, 117, 117, 0.5);
  box-shadow: inset 0 0 6px rgba(123, 117, 117, 0.5);
}

/* Remove arrows from Input type Number in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.chart-wrapper {
  width: 100%;
  height: 100%;
}
.chart-wrapper iframe {
  width: 100%;
  height: 100% !important;
}

.Toastify__toast {
  border-radius: 8px;
  margin: 12px 16px;
  /* the rest copied from the library */
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
}

/* Toastify light mode override */
.Toastify__toast-theme--light.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--light.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--light.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: #00AD7B;
}
.Toastify__toast-theme--light.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--light.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}
.Toastify__progress-bar-theme--light.Toastify__progress-bar--info, .Toastify__progress-bar-theme--light.Toastify__progress-bar--success, .Toastify__progress-bar-theme--light.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--light.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

/* Toastify dark mode override */
.Toastify__toast-theme--dark.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--dark.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--dark.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: #007A57;
}
.Toastify__toast-theme--dark.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--dark.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}
.Toastify__progress-bar-theme--dark.Toastify__progress-bar--info, .Toastify__progress-bar-theme--dark.Toastify__progress-bar--success, .Toastify__progress-bar-theme--dark.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--dark.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}
